///
// CSS hotfixes
///

// Remove top and bottom margins of first and last children
td > * {
  &:first-child {
    margin-top: 0 !important;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}
